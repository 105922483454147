import Web3 from "web3";
// let ethers = require("ethers");

const web3 = new Web3(window.ethereum);

/** 正式网合约 */
// graphql

// 合约地址
let contractAddress = "0x279715cDEdd3E093D5ccF58949C9eCB4C96F628E";
// 合约abi，相当于合约提供的对接文档
let StarPoolAbiJson = require("./abi/StarPoolV2.json");
let StarPoolAbi = StarPoolAbiJson.abi;
let abi = StarPoolAbi

// 测试环境
let defaultChain = process.env.VUE_APP_DEFAULT_CHAIN;

console.log("env", process.env);

if (defaultChain) {
  /** 测试网合约 */
  // 合约地址
  contractAddress = "0xB2715e845EC2C340AA0b81C3493f974e548052cf";

  // 设置默认链
  web3.eth.defaultChain = defaultChain;

}

const contract = new web3.eth.Contract(abi, contractAddress); // 合约


export function toDecimal(value) {
  console.log("toDecimal", value);
  var f = parseFloat(value);
  if (isNaN(f)) {
    return;
  }
  f = Math.round(value * 100) / 100;
  return f;
}

// 时间戳（毫秒）转化为标准时间格式
export function getDateTime(time) {
  // console.log(time)
  var now = new Date(time * 1000);
  var nian = now.getFullYear();
  var yue = (now.getMonth() + 1).toString().padStart(2, "0");
  var ri = now.getDate().toString().padStart(2, "0");
  return `${nian}-${yue}-${ri}`;
}

// 时间戳（毫秒）转化为标准时间格式
export function getTime(time) {
  var now = new Date(time * 1000);
  var nian = now.getFullYear();
  var yue = (now.getMonth() + 1).toString().padStart(2, "0");
  var ri = now.getDate().toString().padStart(2, "0");
  var shi = now.getHours().toString().padStart(2, "0");
  var fen = now.getMinutes().toString().padStart(2, "0");
  var miao = now.getSeconds().toString().padStart(2, "0");
  return `${nian}-${yue}-${ri} ${shi}:${fen}:${miao}`;
}



/**
 * 今日奖励数据
 */
export function rewarTodayInfo() {
  return new Promise((resolve, reject) => {
    contract.methods.rewarTodayInfo().call((err, result) => {
      console.log("今日奖励数据", result);
      resolve(result);
      if (err) reject(err);
    });
  });
}


/**
 * 更新节点排行榜
 * @param {*} _count _count设置太小，则需要更多次才能把排行榜更新完，太大则会导致区块超时。所以选择适当的数量进行更新。
 */
 export function updateNodeRange(_count) {
  const address = localStorage.getItem("address"); //当前用户钱包地址
  console.log("更新节点排行榜", _count);
  return new Promise((resolve, reject) => {
    contract.methods
      .updateNodeRange(_count)
      .send({ from: address }, function (err, result) {
        resolve(result);
        if (err) {
          reject(err);
        }
      });
  });
}
