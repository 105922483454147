<template>
  <div class="wrapper_dialog_admin">
    <div class="relative">
      <transition name="fade" mode="out-in" appear>
        <div class="container">
          <div class="top">
            <span>PoolTwo管理员操作【二池管理后台】</span>
            <router-link :to="{name:'y0JhsHKqX084c33heeDqKHmXfxOfgl2Q',path:'/y0JhsHKqX084c33heeDqKHmXfxOfgl2Q'}">
              <span style="float:right;font-size: 1rem;">StarPool后台</span>
            </router-link>
          </div>
          
          <div class="wallet_items">
            <fieldset>
              <legend>全网算力</legend>
              <div class="desc_admin">
                <div class="desc_admin div-left" ><span>全网算力：{{poolInfo._totalPow}}</span></div>
                <div class="desc_admin div-right"><span>全网质押总额：{{formatAmountMethod(poolInfo._totalSwap)}}</span></div>
                <div class="desc_admin div-left"><span>算力总挖出star：{{formatAmountMethod(poolInfo._totalPowStarMint)}}</span></div>
                <div class="desc_admin div-right"><span>购买算力用户数：{{poolInfo._swapUserCount}}</span></div>
                <div class="desc_admin div-left"><span>star今日产出：{{formatAmountMethod(poolInfo._powRewardToday)}}</span></div>
              </div>
            </fieldset>
            <br/>

            <button @click="chooseWalletForMetaMask" :disabled="getWallet" :class="{disabled_btn:getWallet}">连接钱包</button>
            <div class="msg">{{ address ? "连接成功：" + address : "" }}</div>
            <br/>

            <fieldset>
              <legend>授权</legend>
              <button @click="approveZeed" :disabled="getZeed" :class="{disabled_btn:getZeed}" style="width:32%;float:left;">授权Zeed</button>
              <button @click="approveUsdt" :disabled="getUsdt" :class="{disabled_btn:getUsdt}" style="width:32%;float:left;margin-left: 0.5rem;">授权Usdt</button>
              <button @click="approveStar" :disabled="getStar" :class="{disabled_btn:getStar}" style="width:32%;float:left;margin-left: 0.5rem;">授权Star</button>
            </fieldset>
            <br/>
<!-- 
            <a href="javascript:;" @click="approveZeed">
              <span>approveZeed</span>
            </a>
            <a href="javascript:;" @click="approveUsdt">
              <span>approveUsdt</span>
            </a>
            <a href="javascript:;" @click="approveStar">
              <span>approveStar</span>
            </a> -->
            <fieldset>
              <legend>每日奖励 【今日奖励数据：{{formatAmountMethod(powRewardToday)}}】</legend>
              <!-- <a href="javascript:;" @click="transferSwapBonus">
                <span>每日奖励转入</span>
              </a> -->
              <input class="input_style_admin" type="number" placeholder="算力挖矿每日转入数量"  v-model="powAmount"> 
              <button class="w100" @click="transferSwapBonusForPowAmount" :disabled="getPowerBtn" :class="{disabled_btn:getPowerBtn}">每日奖励转入（算力奖励）</button>
              <hr/>
              <!-- <input class="input_style_admin" type="number" placeholder="节点分配每日转入数量"  v-model="nodeAmount"> 
              <button class="w100" @click="transferSwapBonusForNodeAmount" :disabled="getNodeBtn" :class="{disabled_btn:getNodeBtn}">每日奖励转入（节点奖励）</button>
              <hr/> -->
              <button class="w100" @click="distribSwapBonus" :disabled="getDisBtn" :class="{disabled_btn:getDisBtn}">每日奖励分配</button>
              <br/>
              <fieldset>
                <legend>更新节点排行榜 <span v-if="!hasExpire">无</span><span style="color:red;" v-if="hasExpire">【有】</span>过期数据</legend>
                <input class="input_style_admin" type="number" placeholder="更新节点排行榜"  v-model="updateNodeRangeCount"> 
                <button class="w100" @click="updateNodeRange()" :disabled="updateNodeRangeBtn" :class="{disabled_btn:updateNodeRangeBtn}">更新节点排行榜</button>
              </fieldset>
            </fieldset>

            <br/>
            <fieldset>
              <legend>领取奖励开关状态：{{poolInfo._getRewardSwitch?'开':'关'}} </legend>
              <div/>
              <button @click="setRewardSwitch(true)" style="width:49%;float:left;" :disabled="getRewTrBtn" :class="{disabled_btn:getRewTrBtn}">领取奖励（开）</button>
              <button @click="setRewardSwitch(false)" style="width:49%;float:right;" :disabled="getRewfaBtn" :class="{disabled_btn:getRewfaBtn}">领取奖励（关）</button>
            </fieldset>
            
            <!-- <div style="clear: both;"></div>
            <hr/> -->
            <br/>
            <fieldset>
              <legend>申购池可申购金额：{{remainingAmount}} </legend>
              <input class="input_style_admin" type="number" placeholder="可申购额度"  v-model="addCanSwapAmountNum"> 
              <button style="width:49%;float:left;" @click="addCanSwapAmount()" :disabled="getAddBtn" :class="{disabled_btn:getAddBtn}">增加可申购金额</button>
              <button style="width:49%;float:right;" @click="resetCanSwapAmount()" :disabled="resetCanSwapAmountBtn" :class="{disabled_btn:resetCanSwapAmountBtn}">可兑换金额设置为0</button>
            </fieldset>
            <br/>
            <fieldset>
              <legend>设置质押usdt和zeed的比例（ZEED+USDT=100）</legend>
              <span class="font-left">质押zeed比例：</span><input class="input_style_admin font-input-right" type="number" placeholder="小于等于100的整数" v-model="zeedPercentage">
              <span class="font-left">质押usdt比例：</span><input class="input_style_admin font-input-right" type="number" placeholder="小于等于100的整数" v-model="usdtPercentage">
              <hr />
              <button class="w100" @click="setSwapPercentage" :disabled="getSwapPercentageBtn" :class="{disabled_btn:getSwapPercentageBtn}">设置质押usdt和zeed的比例</button>
              <!-- <hr /> -->
            </fieldset>
            <fieldset>
              <legend>提现操作 </legend>
              <input class="input_style_admin" type="number" placeholder="提现USDT数量"  v-model="withdrawUsdtNum"> 
              <button class="w100" @click="withdrawUsdt()" :disabled="getWithDrBtn" :class="{disabled_btn:getWithDrBtn}">提现USDT</button>
              <hr/>
              <input class="input_style_admin" type="number" placeholder="提现zeed数量"  v-model="withdrawSeedNum"> 
              <button class="w100" @click="withdrawSeed()" :disabled="getWithDSeBtn" :class="{disabled_btn:getWithDSeBtn}">提现zeed</button>
              <hr/>
              <input class="input_style_admin" type="number" placeholder="提现Star数量"  v-model="withdrawStarNum"> 
              <button class="w100" @click="withdrawStar()" :disabled="getWithStarBtn" :class="{disabled_btn:getWithStarBtn}">提现Star</button>
              <hr/>
              <input class="input_style_admin" type="number" placeholder="产量提出数量"  v-model="transferSwapBonusOutPowAmount"> 
              <button class="w100" @click="transferSwapBonusOutClick()" :disabled="getTransferSwapBonusOutBtn" :class="{disabled_btn:getTransferSwapBonusOutBtn}">产量提出</button>
            </fieldset>

            <br/>
     
            
            
          </div>
          
        </div>
      </transition>
    </div>
  </div>
</template>
<style scoped>
.div-left{
  float: left;
}
.div-right{
  float: right;
  text-align: left;
  width: 50%;
}
.font-left{
  width:40% !important;
  float:left;
  line-height: 4rem; 
  text-align: right;
}
.font-input-right{
  width:60% !important;
  float:right
}
.wrapper_dialog_admin{
  background: #2d3d61;
  padding-bottom: 3rem;
}
.wrapper_dialog_admin .container .top{
  font-size: 2rem;
  padding: 1.6rem;
}
.msg {
  font-size: 1rem;
  color: red;
}
.desc_admin{
  margin-top: 0.5rem;
  /* font-size: 1.4rem; */
  font-weight: 500;
}
.desc_admin span{
  margin: 1.5rem;
}
.input_style_admin{
  width: 100%;
  display: block;
  padding: 0 1rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.2rem;
  font-weight: 400;
  width: 100%;
  border: 0.1rem solid #4e4d56;
  border-radius: 0.6rem;
  margin-top: 1rem;
  /* color: #E7F3FC; */
  font-family: BebasNeue-Regular;
}
.wrapper_dialog .container .wallet_items button{
  background: url(../../../public/images/box3.png) no-repeat;
  background-size: 100% 100%;
}
.wallet_items a,.wallet_items button{
  font-size: 1.2rem!important;
  border-radius: 0.6rem;
  justify-content: center!important;
}
.w100{
  width: 100%;
  background: url(../../../public/images/box5.png) no-repeat;
  background-size: 100% 100%;
}
</style>
<script>

const poolTwo = require('../../utils/poolTwo')
const starPoolV2 = require('../../utils/starPoolV2')

export default {
  name: "WalletDialog",
  props: ["id"],
  data() {
    return {
      zeedPercentage: '', //质押zeed比例
      usdtPercentage: '', //质押usdt比例, _zeedPercentage + _usdtPercentage = 100
    
      powAmount:'154.5',//每日奖励转入（算力奖励）
      nodeAmount:'',//每日奖励转入（节点奖励）
      formatAmountMethod:poolTwo.amountConversion,
      ref:'0x68f4d67294bb833d7561a665f2eb1397645586a6',//默认推荐人0x68f4d67294bb833d7561a665f2eb1397645586a6
      address: "",
      canSwapAmount:'',
      nodeRewardToday:0,
      powRewardToday:0,
      remainingAmount:0,
      addCanSwapAmountNum:'',
      withdrawUsdtNum:'',
      withdrawSeedNum:'',
      withdrawStarNum:'',
      poolInfo:{
        _totalPow:0,  // 全网算力
        _totalSwap:0, // 全网质押总额,
        _totalPowStarMint:0, // 算力总挖出star
        _swapUserCount:0, // 购买算力用户数
        _getRewardSwitch:false, // 是否可以领取奖励开关
        _powRewardToday:0,//star今日产出
      },
      regCount:0,//当前注册地址总数量
      getWallet:false, //链接钱包 按钮是否禁用
      getZeed:false, //授权zeed 按钮是否禁用
      getUsdt:false, //授权usdt 按钮是否禁用
      getStar:false, //授权star 按钮是否禁用
      getPowerBtn:false, //每日奖励转入（算力奖励）按钮是否禁用
      getNodeBtn:false, //每日奖励转入（节点奖励）按钮是否禁用
      getDisBtn:false, //每日奖励分配 按钮是否禁用
      getRewTrBtn:false, //领取奖励（开） 按钮是否禁用
      getRewfaBtn:false, //领取奖励（关） 按钮是否禁用
          
      getAddBtn:false, //增加可申购金额 按钮是否禁用
      getWithDrBtn:false, //提现USDT 按钮是否禁用
      getWithDSeBtn:false, //提现zeed 按钮是否禁用
      getWithStarBtn:false, //提现Star 按钮是否禁用
      resetCanSwapAmountBtn:false,//
      getSwapPercentageBtn:false,
      updateNodeRangeCount:'',//
      updateNodeRangeBtn:false,
      hasExpire:false, // 返回数据增加了hasExpire
      getTransferSwapBonusOutBtn:false,
      transferSwapBonusOutPowAmount:'',

    };
  },
  created: function () {
    let that = this
    starPoolV2.rewarTodayInfo().then(res => {
      console.log(res)
      that.hasExpire = res.hasExpire
    })
    // 今日奖励数据
    poolTwo.rewarTodayInfo().then(res => {
      console.log(res)
      // that.nodeRewardToday = res._nodeRewardToday
      that.powRewardToday = res
    })

    // 获取申购池可申购金额
    poolTwo.canSwapAmount().then(res => {
      that.remainingAmount = res
    })

    // 全网算力
    poolTwo.poolInfo().then(res => {
      console.log(res)
      that.poolInfo = res
    })

    poolTwo.zeedPercentage().then(res => {
      that.zeedPercentage = res
    })
    poolTwo.usdtPercentage().then(res => {
      that.usdtPercentage = res
    })


  },
  methods: {
    // 连接钱包 MetaMask
    chooseWalletForMetaMask() {
      var that = this;
      that.getWallet = true;
      if (typeof window.ethereum !== "undefined") {
        const provider = window.ethereum;
        // Stpe1 监听（账号改变、链改变、断开连接）
        provider.on("accountsChanged", (accounts) => {
          // 账号改变
          console.log("账号改变", accounts);
        });
        provider.on("chainChanged", (chainId) => {
          // 链改变
          // window.location.reload()
          console.log("链改变", chainId);
        });
        provider.on("disconnect", (code, reason) => {
          // 断开连接
          console.log("断开连接", code, reason);
        });

        // Stpe2 获取当前钱包账号
       
        provider.request({ method: "eth_requestAccounts" }).then((res) => {
          var address = res[0];
          console.log("当前钱包地址：" + address);
          that.address = address;
          localStorage.setItem('address',address);
          this.$toast(this.$t('menu.extractionSucceeded'));
        });
      } else {
        this.$toast("请安装MetaMask钱包");
      }
      that.getWallet = false;
    },
    // 授权Zeed
    approveZeed(){
      this.getZeed = true;
      poolTwo.approveZeed().then(res => {
        console.log(res)
      })
    },
    // 授权USDT
    approveUsdt(){
      this.getUsdt = true;
      poolTwo.approveUsdt().then(res => {
        console.log(res)
      })
    },
    // 授权Star
    approveStar(){
      this.getStar = true;
      poolTwo.approveStar().then(res => {
        console.log(res)
      })
    },
   
    // 每日奖励转入 onlyGov
    transferSwapBonus(){
      poolTwo.transferSwapBonus('257.5','10.5').then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 每日奖励转入（算力奖励）
    transferSwapBonusForPowAmount(){
      this.getPowerBtn = true;
      let _powAmount = this.powAmount
      poolTwo.transferSwapBonus(_powAmount.toString(),'0').then(res=>{
        console.log('每日奖励转入（算力奖励）',_powAmount,res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 每日奖励转入（节点奖励）
    transferSwapBonusForNodeAmount(){
      this.getNodeBtn = true;
      let _nodeAmount = this.nodeAmount
      poolTwo.transferSwapBonus('0',_nodeAmount.toString()).then(res=>{
        console.log('每日奖励转入（节点奖励）',_nodeAmount,res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 每日奖励分配 onlyGov
    distribSwapBonus(){
      this.getDisBtn = true;
      poolTwo.distribSwapBonus().then(res=>{
        console.log(res,'=========');
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 领取奖励开关
    setRewardSwitch(_switch){
      console.log(_switch)
      if(_switch){
        this.getRewTrBtn = true;
      }else{
        this.getRewfaBtn = true;
      }
      poolTwo.setRewardSwitch(_switch).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 增加可申购金额
    addCanSwapAmount(){
      this.getAddBtn = true;
      let addCanSwapAmountNum = this.addCanSwapAmountNum
      console.log(addCanSwapAmountNum)
      poolTwo.addCanSwapAmount(addCanSwapAmountNum.toString()).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    resetCanSwapAmount(){
      this.resetCanSwapAmountBtn = true;
      poolTwo.resetCanSwapAmount().then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    
    // withdrawUsdt
    withdrawUsdt(){
      this.getWithDrBtn = true;
      let _amount = this.withdrawUsdtNum
      poolTwo.withdrawUsdt(_amount).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // withdrawSeed
    withdrawSeed(){
      this.getWithDSeBtn = true;
      let _amount = this.withdrawSeedNum
      poolTwo.withdrawZeed(_amount).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    
    // withdrawStar
    withdrawStar(){
      this.getWithStarBtn = true;
      let _amount = this.withdrawStarNum
      poolTwo.withdrawStar(_amount).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    //设置质押usdt和star的比例,购买bnb的比例
    setSwapPercentage() {
      this.getSwapPercentageBtn = true;
      let _zeedPercentage = this.zeedPercentage
      let _usdtPercentage = this.usdtPercentage
      poolTwo.setSwapPercentage(_zeedPercentage.toString(), _usdtPercentage.toString(),).then(res => {
        console.log('设置质押usdt和zeed的比例', _zeedPercentage, _usdtPercentage, res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 更新节点排行榜
    updateNodeRange(){
      this.updateNodeRangeBtn = true;
      let _count = this.updateNodeRangeCount
      starPoolV2.updateNodeRange(_count.toString()).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // withdrawStar
    transferSwapBonusOutClick(){
      this.getTransferSwapBonusOutBtn = true;
      let _powAmount = this.transferSwapBonusOutPowAmount
      poolTwo.transferSwapBonusOut(_powAmount).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },

  },
};
</script>
<style scoped></style>
